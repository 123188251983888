<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent" :role="1"></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import locallifeSettlementStoreModule from '@/store/modules/locallifeSettlement'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 底部组件
    Bottom: () => import('./components/Bottom')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('locallifesettlement')) {
      this.$store.registerModule('locallifesettlement', locallifeSettlementStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
  },
  destroyed () {
    // 卸载动态store模块
    this.$store.unregisterModule('locallifesettlement')
  }
}
</script>
